<template>
  <div v-editable="props.blok" class="wp-richtext" :data-uid="props.blok._uid">
    <RichtextResolver :blok="props.blok.content" />
  </div>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: false,
    default: () => {}
  }
})
</script>
